import React from 'react';
import ReactSlick from 'react-slick';
import {
  FiChevronLeft,
  FiChevronRight,
} from 'react-icons/fi';

import './slick.css';

const ChevronLeft = ({ colorScheme, className, style, onClick }) => {
  const color = colorScheme === 'light'
    ? '#ffffff'
    : '#1590e4';

  return (
    <FiChevronLeft
      className={className}
      style={{
        ...style,
        zIndex: '99',
        left: '-16px',
        width: '26px',
        height: '26px',
        color,
      }}
      onClick={onClick}
    />
  );
};

const ChevronRight = ({ colorScheme, className, style, onClick }) => {
  const color = colorScheme === 'light'
    ? '#ffffff'
    : '#1590e4';

  return (
    <FiChevronRight
      className={className}
      style={{
        ...style,
        zIndex: '99',
        right: '-16px',
        width: '26px',
        height: '26px',
        color,
      }}
      onClick={onClick}
    />
  );
};

export default function Slick({ colorScheme, settings, children }) {
  const color = colorScheme === 'light'
    ? '#ffffff'
    : '#1590e4';

  const fullSettings = {
    dots: true,
    customPaging: (i) => (
      <div
        style={{
          margin: '8px 0',
          width: '8px',
          height: '8px',
          border: `1px solid ${color}`,
          borderRadius: '50%',
          backgroundColor: color,
          transition: 'background-color .3s',
        }}
      />
    ),
    prevArrow: <ChevronLeft colorScheme={colorScheme} />,
    nextArrow: <ChevronRight colorScheme={colorScheme} />,
    infinite: true,
    speed: 500,
    ...settings,
  };

  return (
    <ReactSlick {...fullSettings}>
      {children}
    </ReactSlick>
  );
}
