import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './review-card.css';

export default function ReviewCard({ review }) {
  return (
    <div className="review-card">
      <div className="review-card__outer-container">
        <div className="review-card__inner-container">
          <div className="review-card__message">
            {review.message}
          </div>
          <div className="review-card__name">
            <div className="review-card__img">
              <GatsbyImage
                image={review.avatar.childImageSharp.gatsbyImageData}
                alt={review.name}
              />
            </div>
            {review.name}
          </div>
        </div>
      </div>
    </div>
  );
}
