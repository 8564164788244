import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Section from '../components/section';
import Slick from '../components/slick';
import FeedCard from '../components/feed-card';
import DoctorCard from '../components/doctor-card';
import ReviewCard from '../components/review-card';

import PartnerLogo from '../assets/svg/partner-logo.svg';

export default function Home({ data }) {
  const pageInfo = {
    id: 'index-page',
    url: '/',
    title: 'Главная',
    description: data.strapiGeneral.description,
    keywords: data.strapiGeneral.keywords,
    image: data.strapiGeneral.logo.publicURL,
    breadcrumbs: [
      {
        title: 'Главная',
        link: null,
      },
    ],
  };

  return (
    <Layout pageInfo={pageInfo}>
      <Section id="about" colorScheme="dark">
        <h2 className="heading heading--special heading--light">
          <div className="heading__pre">
            Клиника
          </div>
          <div className="heading__main">
            Молекулярной коррекции
          </div>
        </h2>
        <p style={{ textAlign: 'center', color: '#ffffff' }}>
          КМК клиника - первое в России частное медицинское учреждение,
          которое использует современную технологию лечения доброкачественных
          новообразований, основанную на использовании фокусированного
          ультразвука высокой частоты (High Intensity Focused Ultrasound - HIFU).
        </p>
        <div className="row clearfix">
          <Slick
            colorScheme="light"
            settings={{
              slidesToShow: 3,
              slidesToScroll: 3,
              responsive: [
                {
                  breakpoint: 736 + 1, // iphone-plus-landscape
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 568 + 1, // iphone-se-landscape
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],          
            }}
          >
            {
              data.allStrapiOffer.edges
                .map(({ node }) => (
                  <div className="slick-slider__el-wrapper">
                    <div className="slick-slider__el">
                      <FeedCard feed={{ ...node }} link="offers" />
                    </div>
                  </div>
                ))
            }
          </Slick>
        </div>
      </Section>
      <Section>
        <h2 className="heading heading--special heading--dark">
          {/* <div className="heading__pre">
            Высококвалифицированные
          </div> */}
          <div className="heading__main">
            Наш коллектив
          </div>
        </h2>
        <p style={{ textAlign: 'center' }}>
          В КМК клинике работают высококвалифицированные специалисты,
          обладающие значительным опытом, навыками, авторскими методиками лечения.
        </p>
        <div className="row clearfix">
          <Slick
            settings={{
              slidesToShow: 3,
              slidesToScroll: 3,
              responsive: [
                {
                  breakpoint: 736 + 1, // iphone-plus-landscape
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 568 + 1, // iphone-se-landscape
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],          
            }}
          >
            {
              data.allStrapiDoctor.edges
                .map(({ node }) => (
                  <div className="slick-slider__el-wrapper">
                    <div className="slick-slider__el">
                      <DoctorCard doctor={{ ...node }} />
                    </div>
                  </div>
                ))
            }
          </Slick>
        </div>
      </Section>
      <Section colorScheme="dark">
        <h2 className="heading heading--special heading--light">
          <div className="heading__pre">
            Отзывы от
          </div>
          <div className="heading__main">
            Наших пациентов
          </div>
        </h2>
        <p style={{ textAlign: 'center', color: '#ffffff' }}>
          Предлагаем Вам ознакомиться с отзывами пациентов,
          получивших помощь в КМК клинике.
        </p>
        <div className="row clearfix">
          <Slick
            colorScheme="light"
            settings={{
              slidesToShow: 2,
              slidesToScroll: 2,
              responsive: [
                {
                  breakpoint: 568 + 1, // iphone-se-landscape
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],          
            }}
          >
            {
              data.allStrapiReview.edges
                .map(({ node }) => (
                  <div className="slick-slider__el-wrapper">
                    <div className="slick-slider__el">
                      <ReviewCard review={{ ...node }} />
                    </div>
                  </div>
                ))
            }
          </Slick>
        </div>
      </Section>
      <Section>
        <h2 className="heading heading--special heading--dark">
          <div className="heading__pre">
            Наши
          </div>
          <div className="heading__main">
            Партнеры
          </div>
        </h2>
        <p style={{ textAlign: 'center' }}>
          {/* // */}
        </p>
        <div className="row clearfix">
          <a
            className="partner-logo"
            href="https://m.klinikum-nuernberg.de/"
            target="_blank"
          >
            <PartnerLogo />
          </a>
        </div>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    strapiGeneral {
      name
      shortName
      description
      keywords
      logo {
        publicURL
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
    allStrapiOffer(sort: { fields: date, order: DESC }, limit: 5) {
      edges {
        node {
          strapiId
          slug
          title
          date(formatString: "DD.MM.YYYY")
          cover {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiDoctor(sort: { fields: weight, order: ASC }, limit: 7) {
      edges {
        node {
          strapiId
          slug
          name
          description
          avatar {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiReview(sort: { fields: weight, order: ASC }, limit: 7) {
      edges {
        node {
          strapiId
          name
          message
          avatar {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
