import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './doctor-card.css';

export default function DoctorCard({ doctor }) {
  return (
    <div className="doctor-card">
      <div className="doctor-card__img">
        <a href={`/team/${doctor.slug}`}>
          <GatsbyImage
            image={doctor.avatar.childImageSharp.gatsbyImageData}
            alt={doctor.name}
          />
        </a>
      </div>
      <div className="doctor-card__name">
        <h3>
          <a href={`/team/${doctor.slug}`}>
            {doctor.name}
          </a>
        </h3>
      </div>
      <div className="doctor-card__description">
        {doctor.description}
      </div>
    </div>
  );
}
